<template>
    <div class="footer">
        <footer>
			<span class="">Copyright &copy; <span id="footer_year"></span> All rights reserved.  🎄</span> 
		</footer>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="css" scoped>
footer {
    background-color: #f2e9d2;
    text-align: center;
    padding: 2.5rem 0;
}
</style>